<template>
  <f-card>
    <div v-for="(item, index) in lastMonths" :key="index">
      {{ item.date }}: {{ item.have ? "Ok" : $t('labels.notSend') }}
    </div>
  </f-card>
</template>

<script>
export default {
  data: () => ({
    lastMonths: [],
  }),
  computed: {
    distributorID() {
      return this.$store.state.inventory.view.distributorID;
    },
  },
  watch: {
    distributorID() {
      this.getData();
    },
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      const result = await this.$store.dispatch("inventory/monthsHistory");

      this.lastMonths = result.map((item) => {
        return item;
      });
    },
  },
};
</script>
