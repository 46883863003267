import DateFormater from '../../helpers/date_formater'

export default {
  data: () => ({
    // file: null
    errors: [],
    expandedHelp: false,
    distheaders: [],
    goToTotals: false,
    date: DateFormater.lastMonthDate(),
    modal: false,
  }),
  computed: {
    file() {
      return this.$store.state.inventory.view.file
    },
    extras() {
      return this.$store.state.inventory.view.extras
    },
    loading() { return !this.$store.state.system.loading.done },
    formatedDate() {
      var [year, month] = this.date.split('-')
      return this.labels(`${month}${year}`)
    }
  },
  created() {
    this.$store.dispatch('system/loading', 'done')
    if (this.$route.name == 'inventory-upload-process') {
      this.checkFile(null, false)
      this.date = this.$route.params.date
    }
  },
  methods: {
    labels(value) {
      const month = DateFormater.monthNumberToString(value.slice(0, 2))
      return `${month}/${value.slice(2)}`
    },
    pickFile() {
      this.date = this.$refs.dialog.$options.propsData.returnValue
      this.$refs.inputfile.click()
    },
    async checkFile(e, getFile = true) {
      if (getFile) {
        const file = e.target.files[0]
        this.$store.state.inventory.view.file = file
      }

      this.$store.dispatch('system/loading', 'data')
      const headers = await this.$store.dispatch('inventory/checkHeadersConfig')
      this.$store.dispatch('system/loading', 'done')
      this.distheaders = headers
      if (headers) {
        this.errors = []
        this.$store.state.inventory.view.config.headers = headers

        this.$store.dispatch('system/loading', 'data')
        const result = await this.$store.dispatch('inventory/dataProccess', {
          file: this.file,
          date: this.date
        })

        this.$store.dispatch('system/loading', 'done')

        if (result.status) {

          if (
            this.extras.missingPrices ||
            this.extras.missingDates
          ) {
            this.goToTotals = true
          }
          this.nextStep()


        } else {
          this.$store.state.inventory.view.file = null
          if (getFile) {
            e.target.value = ''
          }


          if (result.message == 'NO_FILE') {
            this.$router.push({
              name: 'pos-upload'
            })
          } else if (result.message == 'REQUIRED_HEADERS_NOT_FOUND') {
            this.errors.push({
              type: 'error',
              msg: this.$t('pages.inventory.new.errors.requiredHeadersNotFoud')
            })
          } else if (result.message == 'INSUFFICIENT_NUMBER_OF_COLUMNS') {
            this.errors.push({
              type: 'error',
              msg: this.$t('pages.inventory.new.errors.insufficientNumberOfColumns')
            })
          } else if (result.message == 'DATA_FAIL') {
            if (result.errors.includes('inPartNumberColumn')) {
              this.errors.push({
                type: 'error',
                msg: this.$t('pages.inventory.new.errors.inPartNumberColumn')
              })
            }
            if (result.errors.includes('invalidPartNumberType')) {
              this.errors.push({
                type: 'error',
                msg: this.$t('pages.inventory.new.errors.invalidPartNumberType')
              })
            }
            if (result.errors.includes('invalidPartNumberColumn')) {
              this.errors.push({
                type: 'error',
                msg: this.$t('pages.inventory.new.errors.invalidPartNumberColumn')
              })
            }
            if (result.errors.includes('inQuantityColumn')) {
              this.errors.push({
                type: 'error',
                msg: this.$t('pages.inventory.new.errors.inQuantityColumn')
              })
            }
            if (result.errors.includes('invalidQuantityColumn')) {
              this.errors.push({
                type: 'error',
                msg: this.$t('pages.inventory.new.errors.invalidQuantityColumn')
              })
            }
            if (result.errors.includes('inDateColumn')) {
              this.errors.push({
                type: 'error',
                msg: this.$t('pages.inventory.new.errors.inDateColumn')
              })
            }
            // console.log( result.errors )
          }
          // console.log( result )
        }
      } else {
        this.setConfig()
      }
    },
    setConfig() {
      this.$router.push({
        name: 'inventory-set-config',
        params: { date: this.date }
      })
    },
    nextStep() {
      // var goToTotals = false
      // const totals = this.$store.state.inventory.view.totals
      // for( var prop in this.$store.state.inventory.view.totals ){
      //     if( !totals[prop] ){
      //         goToTotals = true
      //     }
      // }

      if (this.goToTotals) {
        this.$router.push({
          name: 'inventory-missing-data',
          params: { date: this.date }
        })
      } else {
        this.$router.push({
          name: 'inventory-preview'
        })
      }
    }
  }
}
