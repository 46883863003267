import FPrevious from '../../components/inventory/Previous.vue'
import FFileUploader from '../../components/inventory/FileUploader.vue'
import FDistributorSelect from '../../components/inventory/Distributor.vue'


export default {
  components: {
    FPrevious,
    FFileUploader,
    FDistributorSelect
  },
  data: () => ({
    isDistributorSelected: false,
    selInterval: null
  }),
  computed: {
    loading() { return !this.$store.state.system.loading.done }
  },
  created() {
    this.selInterval = setInterval(function () {
      this.checkSelected()
    }.bind(this), 800)
  },
  methods: {
    checkSelected() {
      if (this.$store.state.inventory.view.distributorID) {
        this.isDistributorSelected = true
        return
      }

      this.isDistributorSelected = false
    },
    async save() {
      //
      // console.log('doing nothing')
    },
    back() {
      this.$router.push({
        name: 'inventory-list'
      })
    }
  },
  destroyed() {
    clearInterval(this.selInterval)
    // this.$store.dispatch( 'order/clear', 'view' )
  }
}
